import { image } from "./images";
import Section12card1Img1 from "../utils/Images/section12/image1.png";
import Section12card1Img2 from "../utils/Images/section12/image2.png";
import Section12card1Img3 from "../utils/Images/section12/image3.png";

const section2 = {
  title: "Our golden snitches",
  exp: {
    count: <div>05+</div>,
    desc: "Years of experience",
  },
  data: [
    {
      count: "200+",
      desc: "Happy clients",
    },
    {
      count: "10,000+",
      desc: "Hours saved",
    },
    {
      count: "20+",
      desc: "Industries served",
    },
    {
      count: "100%",
      desc: "Client retention",
    },
  ],
  brand: [
    {
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/7xLSAlG.jpg",
      link: "",
    },
    {
      img: "https://framerusercontent.com/images/xtljcmlB8oXXrn96bwFgxicviMY.svg",
      link: "",
    },
    {
      img: "https://framerusercontent.com/images/ygVXMv4mljYhaCH3cbn5vMsxiGs.svg",
      link: "",
    },
    {
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/EDwbKst.jpg",
      link: "",
    },
    {
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/rdtG0Uo.jpg",
      link: "",
    },
    {
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/PLnmFoe.jpg",
      link: "",
    },
    {
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/eBSd3vG.jpg",
      link: "",
    },
    {
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/eKkDPQw.jpg",
      link: "",
    },
    {
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/Xm6lFm5.jpg",
      link: "",
    },
    {
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/J7EYt3W.jpg",
      link: "",
    },
    {
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/JyJcqcI.jpg",
      link: "",
    },
    {
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/VkPHqnQ.jpg",
      link: "",
    },
    {
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/PG7fJPa.jpg",
      link: "",
    },
    {
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/VrjwWMG.jpg",
      link: "",
    },
    {
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/7z7UfE5.jpg",
      link: "",
    },
    {
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/1S3wulo.jpg",
      link: "",
    },
    {
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/IDTE3Jg.jpg",
      link: "",
    },
  ],
  btn: "Add me to the list",
};

const section3 = {
  title: (
    <>
      <div className="section3_title_desktop">
        {/* Witness undeniable social proof? */}
        Patronas Praises
      </div>
      <div className="section3_title_mobile">Undeniable social proof?</div>
    </>
  ),
  data: [
    {
      // img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/7pYhyL8.jpg",
      img: "https://framerusercontent.com/images/7NG1Tz0UaTkt0rlbxP9RONj3MM.png",
      name: "Cameron Van",
      profession: "Founder, C.E.O- Couro ",
      country: "USA",
      desc: "We are happy with phase one, I have been constantly reaching out to you guys and thanks for being flexible and willing to work on this. Looking forward to add new things to make the app better for users. We appreciate you guys!",
      problem_solve:
        "Problem Solved: Custom libraries for end customers, admin panel, chat module, custom feed etc.",
      projectimg: (
        <div className="mobile_img_client">
          <img
            className="mob_img2"
            src="https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/l9Kcm6P.jpg"
          ></img>
          <img
            className="mob_img1"
            src="https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/WRKKH0q.jpg"
          ></img>
        </div>
      ),
    },
    {
      img: "https://framerusercontent.com/images/AeOQw3L3g1d5PmhCp40Tun70nQU.jpg",
      name: "Rechelle Balanzat",
      profession: "C.E.O, Juliette",
      country: "NY, USA",
      desc: "Manan and his team at modernizing processes are true pleasure to work with. They understood the challenges my business faced and collaborated with me in finding the right solution. They continue to help me push my business forward and I couldn’t recommend a better team for the job, professional, friendly and timely. What else could you ask for?",
      problem_solve:
        "Problem solved: Customer dashboard, analytics, order and payment pages, vendor dashboard, reports module etc.",
      projectimg: (
        <div className="mobile_img_client">
          <img
            className="mob_img2"
            src="https://framerusercontent.com/images/19ggDl1DQBTjyaN3TgpB2vO84E.png"
          ></img>
          <img
            className="mob_img1"
            src="https://framerusercontent.com/images/Si5wV3XhdCwD3kvWaemYYXjPio.png"
          ></img>
        </div>
      ),

      // "src="https://framerusercontent.com/images/19ggDl1DQBTjyaN3TgpB2vO84E.png"",
    },
    {
      img: "https://framerusercontent.com/images/GzmsVI6dGx5XrelGPiNqy1qLE.png",
      name: "Rishu Srivastava",
      profession: "Founder, Tllid",
      country: "",
      desc: "Our app has been doing pretty well, its been 3 months and we have not had users complaining, and its been quite user friendly.",
      problem_solve: "",
      projectimg: (
        <div className="mobile_img_client">
          <img
            className="mob_img2"
            src="https://framerusercontent.com/images/4nhlQvG1covINwqLEyjJvEKLA.png"
          ></img>
          <img
            className="mob_img1"
            src="https://framerusercontent.com/images/3oM8Gia14WaENl6b3GONvOsHg0U.png"
          ></img>
        </div>
      ),
    },
    {
      img: "https://framerusercontent.com/images/R0L9SL0Rcii5daAZJ1raV0lPyCs.png",
      name: "Ron",
      profession: "Managing member, Atlas properties",
      country: "",
      desc: "Manan and his team at Modernizing Processes had been very helpful and diligent in creating the MVP I needed for growing my business!",
      problem_solve:
        "Problem solved: Project management, deal flow, pending RFPs, contracts and payment dashboard.",
      projectimg: (
        <div className="mobile_img_client">
          <img
            className="mob_img2"
            src="https://framerusercontent.com/images/kU5jv0C4aXPjMhulkXkqPqjB724.png"
          ></img>
          <img
            className="mob_img1"
            src="https://framerusercontent.com/images/fWeHR2CrOO0tp0w7OyygILUQREA.png"
          ></img>
        </div>
      ),
    },
    {
      img: "https://framerusercontent.com/images/Lda5tAlHV9iVyAGykTMgS0MDj4.png",
      name: "Tanvir",
      profession: "Co-Founder, Errunds",
      country: "",
      desc: "Its been super awesome to work with them, they used all their knowledge of Glide apps and helped me figure out where we can use glide apps to our advantage!",
      problem_solve: "",
      projectimg: (
        <div className="mobile_img_client">
          <img
            className="mob_img2"
            src="https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/ctZ3pkN.png"
          ></img>
          <img
            className="mob_img1"
            src="https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/yaJ654s.png"
          ></img>
        </div>
      ),
    },
  ],
};
const section4 = {
  data: [
    // {
    //   title: (
    //     <div className="section4_utils_title">
    //       See how we helped <span>Jay!</span>
    //     </div>
    //   ),
    //   desc: "See how we helped Jay from everyday bowl eliminate problems like loss of time and lack of efficiency by automating his company’s workflow. We provided Jay with automated scheduling for each subscriber, meal plan inventory for chefs and delivery tracking for drivers.",
    //   btn: "Automate my workflow",
    //   img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/bAMn4hd.jpg",
    //   video_link: "https://www.youtube.com/embed/q2imqncBqKk",
    //   imgtitle: "Jay Krishnanunny",
    //   imgdesc: "Everyday Bowl",
    // },
    {
      title: (
        <div className="section4_utils_title">
          See how we helped <span>Ruben!</span>
        </div>
      ),
      // desc: "See how we helped Ruben from Yacht notes eliminate problems like loss of time and lack of efficiency by automating his company’s workflow. We provided Ruben with automated scheduling for each subscriber, meal plan inventory for chefs and delivery tracking for drivers.",
      desc: (
        <>
          From an idea on paper to winning the prestigious Mark Challenge –
          discover how we helped Ruben Mena turn his YACHT NOTES vision into
          reality in just 4 weeks! 🏆🛥️
          <a
            className="linkedinlink"
            href="https://www.linkedin.com/pulse/how-our-app-helped-ruben-win-prestigious-mark-challenge-manan-mehta%3FtrackingId=r%252FzFhYR%252BRdSUYEKbWRgTPg%253D%253D/?trackingId=r%2FzFhYR%2BRdSUYEKbWRgTPg%3D%3D"
            target="_blank"
          >
            <div className="txt">Read More</div>
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6667 8.33329V15.8333C16.6667 16.0543 16.5789 16.2663 16.4226 16.4225C16.2663 16.5788 16.0544 16.6666 15.8333 16.6666H4.16667C3.94566 16.6666 3.7337 16.5788 3.57742 16.4225C3.42114 16.2663 3.33334 16.0543 3.33334 15.8333V4.16663C3.33334 3.94561 3.42114 3.73365 3.57742 3.57737C3.7337 3.42109 3.94566 3.33329 4.16667 3.33329H11.6667C11.8877 3.33329 12.0996 3.2455 12.2559 3.08922C12.4122 2.93293 12.5 2.72097 12.5 2.49996C12.5 2.27895 12.4122 2.06698 12.2559 1.9107C12.0996 1.75442 11.8877 1.66663 11.6667 1.66663H4.16667C3.50363 1.66663 2.86775 1.93002 2.3989 2.39886C1.93006 2.8677 1.66667 3.50358 1.66667 4.16663V15.8333C1.66667 16.4963 1.93006 17.1322 2.3989 17.6011C2.86775 18.0699 3.50363 18.3333 4.16667 18.3333H15.8333C16.4964 18.3333 17.1323 18.0699 17.6011 17.6011C18.0699 17.1322 18.3333 16.4963 18.3333 15.8333V8.33329C18.3333 8.11228 18.2455 7.90032 18.0893 7.74404C17.933 7.58776 17.721 7.49996 17.5 7.49996C17.279 7.49996 17.067 7.58776 16.9107 7.74404C16.7545 7.90032 16.6667 8.11228 16.6667 8.33329Z"
                  fill="white"
                  fill-opacity="0.5"
                />
                <path
                  d="M17.5 1.66663H15C14.779 1.66663 14.567 1.75442 14.4108 1.9107C14.2545 2.06698 14.1667 2.27895 14.1667 2.49996C14.1667 2.72097 14.2545 2.93293 14.4108 3.08922C14.567 3.2455 14.779 3.33329 15 3.33329H15.4917L9.40835 9.40829C9.25143 9.56521 9.16327 9.77804 9.16327 9.99996C9.16327 10.2219 9.25143 10.4347 9.40835 10.5916C9.56526 10.7485 9.77809 10.8367 10 10.8367C10.2219 10.8367 10.4348 10.7485 10.5917 10.5916L16.6667 4.50829V4.99996C16.6667 5.22097 16.7545 5.43293 16.9108 5.58922C17.067 5.7455 17.279 5.83329 17.5 5.83329C17.721 5.83329 17.933 5.7455 18.0893 5.58922C18.2455 5.43293 18.3333 5.22097 18.3333 4.99996V2.49996C18.3333 2.27895 18.2455 2.06698 18.0893 1.9107C17.933 1.75442 17.721 1.66663 17.5 1.66663Z"
                  fill="white"
                  fill-opacity="0.5"
                />
              </svg>
            </div>
          </a>
        </>
      ),
      // btn: "Automate my workflow",
      btn: "Craft my MVP",
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/bAMn4hd.jpg",
      video_link: "https://www.youtube.com/embed/P1IbYbwOHiM",
      imgtitle: "Jay Krishnanunny",
      img_link:
        "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/1691672528734.png",
      imgdesc: "Everyday Bowl",
    },
    {
      title: (
        <div className="section4_utils_title">
          See how we helped <span>Rechelle!</span>
        </div>
      ),
      desc: (
        <>
          How Rechelle Balanzat revolutionized NYC's laundry game. See how our
          partnership with JULIETTE led to doubled orders, 85% client retention,
          and 2000+ hours saved. 🌆📈
          <a
            className="linkedinlink"
            href="https://www.linkedin.com/pulse/how-did-we-help-rechelle-build-innovative-technology-disrupt-mehta-7twmf/"
            target="_blank"
          >
            <div className="txt">Read More</div>
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6667 8.33329V15.8333C16.6667 16.0543 16.5789 16.2663 16.4226 16.4225C16.2663 16.5788 16.0544 16.6666 15.8333 16.6666H4.16667C3.94566 16.6666 3.7337 16.5788 3.57742 16.4225C3.42114 16.2663 3.33334 16.0543 3.33334 15.8333V4.16663C3.33334 3.94561 3.42114 3.73365 3.57742 3.57737C3.7337 3.42109 3.94566 3.33329 4.16667 3.33329H11.6667C11.8877 3.33329 12.0996 3.2455 12.2559 3.08922C12.4122 2.93293 12.5 2.72097 12.5 2.49996C12.5 2.27895 12.4122 2.06698 12.2559 1.9107C12.0996 1.75442 11.8877 1.66663 11.6667 1.66663H4.16667C3.50363 1.66663 2.86775 1.93002 2.3989 2.39886C1.93006 2.8677 1.66667 3.50358 1.66667 4.16663V15.8333C1.66667 16.4963 1.93006 17.1322 2.3989 17.6011C2.86775 18.0699 3.50363 18.3333 4.16667 18.3333H15.8333C16.4964 18.3333 17.1323 18.0699 17.6011 17.6011C18.0699 17.1322 18.3333 16.4963 18.3333 15.8333V8.33329C18.3333 8.11228 18.2455 7.90032 18.0893 7.74404C17.933 7.58776 17.721 7.49996 17.5 7.49996C17.279 7.49996 17.067 7.58776 16.9107 7.74404C16.7545 7.90032 16.6667 8.11228 16.6667 8.33329Z"
                  fill="white"
                  fill-opacity="0.5"
                />
                <path
                  d="M17.5 1.66663H15C14.779 1.66663 14.567 1.75442 14.4108 1.9107C14.2545 2.06698 14.1667 2.27895 14.1667 2.49996C14.1667 2.72097 14.2545 2.93293 14.4108 3.08922C14.567 3.2455 14.779 3.33329 15 3.33329H15.4917L9.40835 9.40829C9.25143 9.56521 9.16327 9.77804 9.16327 9.99996C9.16327 10.2219 9.25143 10.4347 9.40835 10.5916C9.56526 10.7485 9.77809 10.8367 10 10.8367C10.2219 10.8367 10.4348 10.7485 10.5917 10.5916L16.6667 4.50829V4.99996C16.6667 5.22097 16.7545 5.43293 16.9108 5.58922C17.067 5.7455 17.279 5.83329 17.5 5.83329C17.721 5.83329 17.933 5.7455 18.0893 5.58922C18.2455 5.43293 18.3333 5.22097 18.3333 4.99996V2.49996C18.3333 2.27895 18.2455 2.06698 18.0893 1.9107C17.933 1.75442 17.721 1.66663 17.5 1.66663Z"
                  fill="white"
                  fill-opacity="0.5"
                />
              </svg>
            </div>
          </a>
        </>
      ),
      // btn: "Automate my workflow",
      btn: "Maximize my efficiency",
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/bAMn4hd.jpg",
      video_link: "https://www.youtube.com/embed/P1IbYbwOHiM",
      img_link:
        "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/1696006200715.png",

      imgtitle: "Jay Krishnanunny",
      imgdesc: "Everyday Bowl",
    },
    {
      title: (
        <div className="section4_utils_title">
          See how we helped <span>Andrea!</span>
        </div>
      ),
      desc: (
        <>
          From hotel management frustration to tech-driven triumph: Learn how
          Andrea Lasalandra harnessed our expertise to revolutionize his hotel,
          boosting staff performance by 50% and reducing turnover by 60%! 🏨💻
          <a
            className="linkedinlink"
            href="https://www.linkedin.com/pulse/how-did-we-help-andrea-boost-his-hotel-staff-50-launch-manan-mehta%3FtrackingId=WuzPvzRrShGc3D9TZ4R22g%253D%253D/?trackingId=WuzPvzRrShGc3D9TZ4R22g%3D%3D"
            target="_blank"
          >
            <div className="txt">Read More</div>
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6667 8.33329V15.8333C16.6667 16.0543 16.5789 16.2663 16.4226 16.4225C16.2663 16.5788 16.0544 16.6666 15.8333 16.6666H4.16667C3.94566 16.6666 3.7337 16.5788 3.57742 16.4225C3.42114 16.2663 3.33334 16.0543 3.33334 15.8333V4.16663C3.33334 3.94561 3.42114 3.73365 3.57742 3.57737C3.7337 3.42109 3.94566 3.33329 4.16667 3.33329H11.6667C11.8877 3.33329 12.0996 3.2455 12.2559 3.08922C12.4122 2.93293 12.5 2.72097 12.5 2.49996C12.5 2.27895 12.4122 2.06698 12.2559 1.9107C12.0996 1.75442 11.8877 1.66663 11.6667 1.66663H4.16667C3.50363 1.66663 2.86775 1.93002 2.3989 2.39886C1.93006 2.8677 1.66667 3.50358 1.66667 4.16663V15.8333C1.66667 16.4963 1.93006 17.1322 2.3989 17.6011C2.86775 18.0699 3.50363 18.3333 4.16667 18.3333H15.8333C16.4964 18.3333 17.1323 18.0699 17.6011 17.6011C18.0699 17.1322 18.3333 16.4963 18.3333 15.8333V8.33329C18.3333 8.11228 18.2455 7.90032 18.0893 7.74404C17.933 7.58776 17.721 7.49996 17.5 7.49996C17.279 7.49996 17.067 7.58776 16.9107 7.74404C16.7545 7.90032 16.6667 8.11228 16.6667 8.33329Z"
                  fill="white"
                  fill-opacity="0.5"
                />
                <path
                  d="M17.5 1.66663H15C14.779 1.66663 14.567 1.75442 14.4108 1.9107C14.2545 2.06698 14.1667 2.27895 14.1667 2.49996C14.1667 2.72097 14.2545 2.93293 14.4108 3.08922C14.567 3.2455 14.779 3.33329 15 3.33329H15.4917L9.40835 9.40829C9.25143 9.56521 9.16327 9.77804 9.16327 9.99996C9.16327 10.2219 9.25143 10.4347 9.40835 10.5916C9.56526 10.7485 9.77809 10.8367 10 10.8367C10.2219 10.8367 10.4348 10.7485 10.5917 10.5916L16.6667 4.50829V4.99996C16.6667 5.22097 16.7545 5.43293 16.9108 5.58922C17.067 5.7455 17.279 5.83329 17.5 5.83329C17.721 5.83329 17.933 5.7455 18.0893 5.58922C18.2455 5.43293 18.3333 5.22097 18.3333 4.99996V2.49996C18.3333 2.27895 18.2455 2.06698 18.0893 1.9107C17.933 1.75442 17.721 1.66663 17.5 1.66663Z"
                  fill="white"
                  fill-opacity="0.5"
                />
              </svg>
            </div>
          </a>
        </>
      ),
      // btn: "Automate my workflow",
      btn: "Revolutionize my idea",
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/bAMn4hd.jpg",
      video_link: "https://www.youtube.com/embed/P1IbYbwOHiM",
      img_link:
        "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/1692191237762.png",
      imgtitle: "Jay Krishnanunny",
      imgdesc: "Everyday Bowl",
    },
  ],
};

const section5 = {
  title: "Regardless of your growth stage",
  desc: (
    <div>
      "We have the expertise to help you launch your MVP{" "}
      <span>just in 14 days.</span>
    </div>
  ),
  btn: "Launch me now",
};

const section6 = {
  title: "The No-Code wizards",
  items: [
    {
      img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/Manan.png",
      // img: "https://i.imgur.com/vxGyHgZ.png",
      name: "Manan Mehta",
      designation: "Managing Director & Co-Founder Of No-Script",
      txt: `Working aroound a super indulgent and helpful team, shooting videos is always fun!`,
    },
    {
      img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/shantanu.png",
      // img: "https://i.imgur.com/zuv03g3.png",
      name: "Shantanu Iyengar",
      // designation: "Native App Developer And Automation Expert",
      txt: `Native App developer who found a better way to make mobile and web applications`,
    },
    {
      // img: "https://i.imgur.com/gnd3mhi.png",
      img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/sumer.png",
      name: "Sumer Arvind Bhadra",
      // designation: "A Glide App Developer",
      txt: `A glide app developer`,
    },
    {
      // img: "https://i.imgur.com/Y8wrbtE.png",
      img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/Harsh.png",
      name: "Harsh Ganatra",
      // designation: "Project Manager and Client Communication Head",
      txt: `Project manager and client communication head`,
    },
    {
      // img: "https://i.imgur.com/Y8wrbtE.png",
      img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/KD.png",
      name: " Kaustubh Dedhia",
      // designation: "Project Manager and Client Communication Head",
      // txt: `Solving complex logic using Low - Code`,
      txt: `A glide app developer`,
    },
    {
      // img: "https://i.imgur.com/Y8wrbtE.png",
      img: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/prathamesh.png",
      name: "Prathamesh Pendharkar",
      // designation: "Project Manager and Client Communication Head",
      // txt: `The opportunities to grow to grow and learn both personally and professionally are incredible.`,
      txt: `Quality Assurance & Jr Business Analyst`,
    },
    {
      // img: "https://i.imgur.com/Y8wrbtE.png",
      img: "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/imgur/sNCmwrd.png",
      name: "Harsh Ganatra",
      // designation: "Project Manager and Client Communication Head",
      txt: `Project manager and client communication head`,
    },
    // {
    //   img: "https://i.imgur.com/vuNG2fV.png",
    //   name: "Kaustabh Dedhia",
    //   designation: "Glide Developer",
    //   txt: `Solving complex logix using Low - Code`,
    // },
    // {
    //   img: "https://i.imgur.com/IS32LH0.png",
    //   name: "Prathamesh Pendharkar",
    //   designation: "Quality Assurance & Jr.Business Analyst",
    //   txt: `Is the opportunities to grow and learn both personally and professionally are incredible`,
    // },
    // {
    //   img: "https://i.imgur.com/G2hRxyk.png",
    //   name: "Shraddha Chettiar",
    //   designation: "Business Analyst",
    //   txt: `I get to add more skills and grow myself more as BA with every passing project`,
    //   left: 25,
    // },
    // {
    //   img: "https://i.imgur.com/kLsGpPO.png",
    //   name: "Punitesh Poojary",
    //   designation: "Senior Developer",
    //   txt: `I admire the innovative approach of modernizing processes through customized apps that enhance business control, improve efficiency, and make a real-world impact.`,
    //   left: 25,
    // },
    // {
    //   img: "https://i.imgur.com/95rSJ19.png",
    //   name: "Kathan Mehta",
    //   designation: "EdTech and Ads Copywriter",
    //   txt: `Working around a super indulgent and helpful team, shooting videos is always fun`,
    // },
    // {
    //   img: "https://i.imgur.com/3giXurf.png",
    //   name: "Adarshh Kumar",
    //   designation: "Brand Manager",
    //   txt: `The ceiling to learn here is extremely high`,
    // },
    // {
    //   img: "https://i.imgur.com/1lfPUTR.png",
    //   name: "Raya Mohan",
    //   designation: "Videographer",
    //   txt: `I have flexibility when needed and the camaraderie amongst the team is fun - everyone can speak their mind and are valued and heard`,
    // },
    // {
    //   img: "https://i.imgur.com/29vvciP.png",
    //   name: "Gorande Katira",
    //   designation: "Content Writing",
    //   txt: `The work culture that we have, work doesn't feel like a burden here.`,
    // },
    // {
    //   img: "https://i.imgur.com/L2pXbkT.png",
    //   name: "Dhiraj Ambekar",
    //   designation: "Front-End Developer",
    //   txt: `Working around a super indulgent and helpful team, making websites pages is always fun!`,
    // },
  ],
  btn: "Get expert guidance",
};

const section7 = {
  title: "How will your journey look like",
  item: [
    {
      name: "Discovery call",
      translate: "(Lumos revelation)",
      desc: "A 30 minutes call where we shed light on your needs and aspirations, illuminating your path to digital transformation. We also solidify the spell in the form of a Hermonie- esque contract. ",
      img: image.discovery_call,
      img2: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/hp_1-transformed%201.svg",
    },
    {
      name: "Project architecture",
      translate: "(Accio blueprint)",
      desc: "This is where we design a comprehensive plan, like a magically summoned blueprint, to lay foundation for a successful project. We also showcase a lea MVP to give you a taste of the future.",
      img: image.project_architecture,
      img2: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/hp1-transformed%201.svg",
    },
    {
      name: "Development",
      translate: "(Incendio codecraft)",
      desc: "This is where the real magic starts, our team of Magicians start creating a powerful spell leveraging the magic of Glide and No-Code. You also get a progress report of how things are progressing",
      img: image.development,
      img2: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/h3-transformed%201.svg",
    },
    {
      name: "Testing",
      translate: "(Obliviate analytica)",
      desc: "We use the expertise to cast the enchantus analytica charm on any potential flaws, analyzing everything in pursuit of perfection!",
      img: image.testing,
      img2: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/h4-transformed%201.svg",
    },
    {
      name: "Scalability roadmap",
      translate: "(Enchantus expansion)",
      desc: "Before we conclude our journey with you, we map out a scalability future, seamlessly expanding your digital capabilities to empower your business to soar to new dimensions.",
      img: image.scalability_roadmap,
      img2: "https://modernizingprocesses.nyc3.cdn.digitaloceanspaces.com/Landing/h8-transformed%201.svg",
    },
  ],
};
const section8 = {
  title: "App handover ceremony",
  sub_title: "The Expecto Proficientia",
  // desc: "This is where we deliver the final product with proficiency, hence evoking the sprit of the Expecto Petronum charm to ward off any future challenges.",
  desc: "We deliver the final product with proficiency, evoking the spirit of the Expecto Patronum charm to ward off future challenges",
  btn: "Book my call already",
};

const section9 = {
  title: "Modernizing Processes",
  sub: "Is a perfect fit for you if",
  items: [
    {
      title: "Unshakeable Albus",
      desc: "You are ready to forge a startup legacy with unwavering determination!",
      tick: image.tick,
    },
    {
      title: "Lightening Harry",
      desc: "You are looking to scale up quickly and hence don't have months to waste on a native app!",
      tick: image.tick,
    },
    {
      title: "Curious Hermoine",
      desc: "You are willing to be actively involved with developers!",
      tick: image.tick,
    },
    {
      title: "Loyal Hagrid",
      desc: "You are looking for a long term tech partner who will continuously improve your process as you grow!",
      tick: image.tick,
    },
  ],
  btn: "I’m a perfect fit",
};

const section11 = {
  title: "Hoot hoot!",
  sub_title: "Hi there, esteemed witch or wizard! I am Aurelius, Hedwig’s son.",
  sub_title2: "Drop your email below to stay updated with monthly newsletter.",
  desc: "Pssst.... no spam here! We banned it after that ear-splitting howler from Ron’s mother.",
};
const section12 = {
  title: (
    <>
      Unlock the Secrets to Successfully Launch Your MVP with the{" "}
      <span>LAUNCH</span> Framework{" "}
    </>
  ),
  desc: "The LAUNCH Framework is your ultimate guide to successfully building and launching your first MVP. Designed specifically for first-time founders, this video masterclass will take you step-by-step through everything you need to know.",
  sub_title: "What will you gain access to",
  cards: [
    {
      img: Section12card1Img1,
      title: "Essential Audience Analysis Questions",
      desc: "Understand your audience like never before.",
    },
    {
      img: Section12card1Img2,
      title: "Top 3MVP Features Scoring Framework",
      desc: "Focus on what matters most.",
    },
    {
      img: Section12card1Img3,
      title: "Go-To-Market Strategy Checklist",
      desc: "Launch with confidence.",
    },
  ],
};
const getUrlFromPath = (path) =>
  path.replace(
    "../utils",
    "https://mp-uploads.blr1.cdn.digitaloceanspaces.com/website-resources/utils"
  );
export const page_data = {
  section2,
  section3,
  section4,
  section5,
  section6,
  section7,
  section8,
  section9,
  section11,
  section12,
  getUrlFromPath,
};

import mixpanel from "mixpanel-browser";

const BookACall = ({ custClass = "", txt = "Book a call", click }) => {
  // Parsing URL parameters for campaign and audience
  const urlParams = new URLSearchParams(window.location.search);
  const campaign = urlParams.get("campaign") || "";
  const audience = urlParams.get("audience") || "";
  return (
    <div
      className={`book-a-call ${custClass}`}
      onClick={(e) => {
        mixpanel.track("Button-Clicked", {
          text: txt || "",
          campaign,
          audience,
        });
        if (click) {
          click(e);
        }
      }}
    >
      {txt}
    </div>
  );
};

export { BookACall };

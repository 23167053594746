import React, { useState } from "react";
import { BookACall } from "./Buttons/BookACall";
import { image } from "../utils/images";

export const Navbar = ({
  navbar_active_section,
  setnavbar_active_section,
  thankYou,
}) => {
  const [HamMobileHandler, setHamMobileHandler] = useState(false);

  return (
    <div className="navbar">
      <div className="container">
        {/* Logo Section */}
        <div className="logo">{image.logo}</div>

        {/* Mobile Hamburger Menu */}
        <div
          className={`mobile_hamberger ${HamMobileHandler ? "active_span" : ""}`}
          onClick={() => setHamMobileHandler((prev) => !prev)}
        >
          {!HamMobileHandler ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M24.5 14C24.5 14.2321 24.4078 14.4546 24.2437 14.6187C24.0796 14.7828 23.8571 14.875 23.625 14.875H4.375C4.14294 14.875 3.92038 14.7828 3.75628 14.6187C3.59219 14.4546 3.5 14.2321 3.5 14C3.5 13.7679 3.59219 13.5454 3.75628 13.3813C3.92038 13.2172 4.14294 13.125 4.375 13.125H23.625C23.8571 13.125 24.0796 13.2172 24.2437 13.3813C24.4078 13.5454 24.5 13.7679 24.5 14ZM4.375 7.875H23.625C23.8571 7.875 24.0796 7.78281 24.2437 7.61872C24.4078 7.45462 24.5 7.23206 24.5 7C24.5 6.76794 24.4078 6.54538 24.2437 6.38128C24.0796 6.21719 23.8571 6.125 23.625 6.125H4.375C4.14294 6.125 3.92038 6.21719 3.75628 6.38128C3.59219 6.54538 3.5 6.76794 3.5 7C3.5 7.23206 3.59219 7.45462 3.75628 7.61872C3.92038 7.78281 4.14294 7.875 4.375 7.875ZM23.625 20.125H4.375C4.14294 20.125 3.92038 20.2172 3.75628 20.3813C3.59219 20.5454 3.5 20.7679 3.5 21C3.5 21.2321 3.59219 21.4546 3.75628 21.6187C3.92038 21.7828 4.14294 21.875 4.375 21.875H23.625C23.8571 21.875 24.0796 21.7828 24.2437 21.6187C24.4078 21.4546 24.5 21.2321 24.5 21C24.5 20.7679 24.4078 20.5454 24.2437 20.3813C24.0796 20.2172 23.8571 20.125 23.625 20.125Z"
                fill="#999999"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="29"
              viewBox="0 0 28 29"
              fill="none"
            >
              <path
                d="M22.4946 22.2149C22.5758 22.2962 22.6403 22.3927 22.6843 22.499C22.7283 22.6052 22.751 22.719 22.751 22.834C22.751 22.949 22.7283 23.0628 22.6843 23.169C22.6403 23.2752 22.5758 23.3718 22.4946 23.453C22.4133 23.5343 22.3167 23.5988 22.2105 23.6428C22.1043 23.6868 21.9905 23.7095 21.8755 23.7095C21.7605 23.7095 21.6467 23.6868 21.5405 23.6428C21.4342 23.5988 21.3377 23.5343 21.2564 23.453L14.0005 16.196L6.74455 23.453C6.58036 23.6172 6.35768 23.7095 6.12549 23.7095C5.89329 23.7095 5.67061 23.6172 5.50642 23.453C5.34224 23.2889 5.25 23.0662 5.25 22.834C5.25 22.6018 5.34224 22.3791 5.50642 22.2149L12.7635 14.959L5.50642 7.70305C5.34224 7.53886 5.25 7.31618 5.25 7.08398C5.25 6.85179 5.34224 6.62911 5.50642 6.46492C5.67061 6.30073 5.89329 6.2085 6.12549 6.2085C6.35768 6.2085 6.58036 6.30073 6.74455 6.46492L14.0005 13.722L21.2564 6.46492C21.4206 6.30073 21.6433 6.2085 21.8755 6.2085C22.1077 6.2085 22.3304 6.30073 22.4946 6.46492C22.6587 6.62911 22.751 6.85179 22.751 7.08398C22.751 7.31618 22.6587 7.53886 22.4946 7.70305L15.2375 14.959L22.4946 22.2149Z"
                fill="#999999"
              />
            </svg>
          )}
        </div>

        {/* Navigation Links */}
        {!thankYou && (
          <div className="nav-link-group">
            {[
              { to: "our-clients", txt: "Our clients" },
              { to: "our-team", txt: "Our team" },
              { to: "how-it-works", txt: "How it works" },
            ].map((el) => (
              <a
                key={el.to}
                href={`#${el.to}`}
                className={`nav-link ${
                  el.to === navbar_active_section ? "active" : ""
                }`}
                onClick={() => setnavbar_active_section(el.to)}
              >
                {el.txt}
              </a>
            ))}
          </div>
        )}

        {/* Book a Call Button */}
        <BookACall
          click={() => {
            const launch_now = document.getElementById("launch_now");
            launch_now?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }}
          txt="Launch Now"
        />
      </div>

      {/* Mobile Navigation Links */}
      <div className={`nav-link-group_mobile ${HamMobileHandler ? "hamActive" : ""}`}>
        {[
          { to: "our-clients", txt: "Our clients" },
          { to: "our-team", txt: "Our team" },
          { to: "how-it-works", txt: "How it works" },
        ].map((el) => (
          <a
            key={el.to}
            href={`#${el.to}`}
            className={`nav-link ${
              el.to === navbar_active_section ? "active" : ""
            }`}
            onClick={() => {
              setnavbar_active_section(el.to);
              setHamMobileHandler(false);
            }}
          >
            {el.txt}
          </a>
        ))}

        {/* Book a Call Button for Mobile */}
        <BookACall
          custclass="book_mobile_nav"
          click={() => {
            const launch_now = document.getElementById("launch_now");
            launch_now?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }}
          txt="Launch Now"
        />
      </div>
    </div>
  );
};

// App.js

import "./style/main.css";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useEffect, useState, lazy, Suspense, memo } from "react";
import AOS from "aos";
import Hotjar from "@hotjar/browser";

import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import { BookACall } from "./components/Buttons/BookACall";
import { page_data } from "./utils/utils";
import mixpanel from "mixpanel-browser";

// Adjusted React.lazy imports for named and default exports

// Named exports
const Section1 = lazy(() =>
  import("./sections/Section1").then((module) => ({ default: module.Section1 }))
);
const Section1_1 = lazy(() =>
  import("./sections/Section1_1").then((module) => ({
    default: module.Section1_1,
  }))
);
const Section10 = lazy(() =>
  import("./sections/Section10").then((module) => ({
    default: module.Section10,
  }))
);
const NewSection = lazy(() =>
  import("./sections/NewSection").then((module) => ({
    default: module.NewSection,
  }))
);

// Default exports
const Section2 = lazy(() => import("./sections/Section2"));
const Section3 = lazy(() => import("./sections/Section3"));
const Section4 = lazy(() => import("./sections/Section4"));
const Section5 = lazy(() => import("./sections/Section5"));
const Section6 = lazy(() => import("./sections/Section6"));
const Section7 = lazy(() => import("./sections/Section7"));
const Section8 = lazy(() => import("./sections/Section8"));
const Section9 = lazy(() => import("./sections/Section9"));
const Section12 = lazy(() => import("./sections/Section12"));

// Retrieve icon URLs using utility function
const PDFICON = page_data.getUrlFromPath("../utils/Images/thankyou/Frame.svg");
const DownloadICON = page_data.getUrlFromPath(
  "../utils/Images/thankyou/Frame-1.svg"
);

// Define video items outside the component to prevent re-creation on each render
const videoItems = [
  // ... your video items
];

const project_token = "bb589b5363e45b1b7f79df183651c651";
try {
  mixpanel.reset();
} catch (error) {
  console.log("mixpanel clear error");
}
console.log("here =>", mixpanel);

mixpanel.init(project_token);
/**
 * VideoItem Component
 * Renders individual video items with optional document links.
 * Wrapped with React.memo to prevent unnecessary re-renders.
 */
const VideoItem = memo(({ item, index }) => (
  <div className="video_item" key={index}>
    <div className="title">
      Step {index + 1}: {item.title}
    </div>
    <div className="videoContainer">
      <iframe
        src={item.link}
        title={`YouTube video player ${index + 1}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
    {item.doc && (
      <div className="document" onClick={() => window.open(item.doc, "_blank")}>
        <img src={PDFICON} alt="PDF Icon" />
        <div className="txt">{item.title}</div>
        <img src={DownloadICON} alt="Download Icon" />
      </div>
    )}
  </div>
));

Hotjar.init(5254807, 6);
function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [navbarActiveSection, setNavbarActiveSection] = useState("");
  const [thankYou, setThankYou] = useState(false);
  const [loadedSections, setLoadedSections] = useState([]);

  const handleBookACallClick = () => {
    const launchNow = document.getElementById("launch_now");
    if (launchNow) {
      launchNow.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleSectionLoad = (sectionName) => {
    setLoadedSections((prev) => [...prev, sectionName]);
  };

  const sections = [
    { Component: Section1, name: "Section1" },
    { Component: Section1_1, name: "Section1_1" },
    { Component: Section2, name: "Section2" },
    { Component: Section12, name: "Section12" },
    { Component: Section3, name: "Section3" },
    { Component: Section4, name: "Section4" },
    { Component: Section5, name: "Section5" },
    { Component: Section6, name: "Section6" },
    { Component: NewSection, name: "NewSection" },
    { Component: Section7, name: "Section7" },
    { Component: Section8, name: "Section8" },
    { Component: Section9, name: "Section9" },
    { Component: Section10, name: "Section10" },
    { Component: Footer, name: "Footer" },
  ];

  return (
    <div className="Landing">
      <Navbar
        navbar_active_section={navbarActiveSection}
        setnavbar_active_section={setNavbarActiveSection}
        thankYou={thankYou}
      />

      {thankYou ? (
        <div className="container">
          <div className="thankyoupage">
            <div className="text_group">
              <div>
                Unlock the Secrets to Successfully Launch Your MVP with the
                LAUNCH Framework
              </div>
              <div>Are you ready to turn your MVP idea into reality?</div>
              <div>
                The LAUNCH Framework is your ultimate guide to successfully
                building and launching your first MVP. Designed specifically for
                first-time founders, this video masterclass will take you
                step-by-step through everything you need to know.
              </div>
            </div>
            <div className="video_group">
              {videoItems.map((item, index) => (
                <VideoItem key={index} item={item} index={index} />
              ))}
            </div>
            <BookACall
              custClass="floatbtn"
              click={handleBookACallClick}
              txt="Book a call now"
            />
          </div>
        </div>
      ) : (
        sections.map(({ Component, name }) => (
          <Suspense fallback={<div key={name}></div>} key={name}>
            <Component />
          </Suspense>
        ))
      )}
    </div>
  );
}

export default App;
